import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  data() {
    return {};
  },
  methods: {
    hasFieldAfterTable(item, index) {
      let afterIndex = -1;


      for (let i = index - 1; i >= 0; i--) {
        if (
          (this.fieldsData[i].inputType == "DATA_TABLE" &&
            this.fieldsData[i].table_data_view_type != "Accordion_view") ||
          (this.fieldsData[i].inputType == "ENTITY_TABLE" && this.fieldsData[i].table_data_view_type != "Accordion_view") ||
          this.fieldsData[i].inputType == "DIVISION" ||
          this.fieldsData[i].inputType == "ENTITY_VARIABLE" ||
          (this.fieldsData[i].input_type == "RICH_TEXT" && this.fieldsData[i].inputType == "MULTI_LINE_TEXT")
        ) {
          afterIndex = i;
          break;
        }
      }


      if (afterIndex > -1) {
        return [this.fieldsData[afterIndex], afterIndex];
      }
      return [null, -1];
    },


    getStyle(item, index) {
      let zIndex = this.hideFields.filter((hf) => hf == item.key).length
        ? -1
        : 0;


      if (this.interactiveQuestionsMode) {
        if (this.interactiveQuestionsModeType == "vertical") {
          return (
            `position:relative;left:${50}px;top:${this.getFieldYValueFromHeight(
              item
            )}px;height:${item.height}px;width:${item.width}px;` +
            (zIndex == -1 ? "z-index : -1;" : "")
          );
        } else {
          return (
            `position:absolute;left:150px;top:50px;height:${item.height}px;width:${item.width}px;` +
            (zIndex == -1 ? "z-index : -1;" : "")
          );
        }
      } else {
        let [dataTableField, dataTableFieldIndex] = this.hasFieldAfterTable(
          item,
          index
        );


        if (dataTableField) {
          item.listen_height = true;


          let cDiff = 0;
          let cActualDiff = 0;
          let divisionFieldKey = "";




           while (dataTableFieldIndex > -1) {
            let elements = document.getElementsByClassName(
              "dataTable" + dataTableFieldIndex
            );
            //We need to calculate height difference of DATA_TABLE, DIVISION and ENTITY_TABLE only


            if (
              elements[0] &&
              (this.fieldsData[dataTableFieldIndex].inputType == "DATA_TABLE" ||
                this.fieldsData[dataTableFieldIndex].inputType ==
                  "ENTITY_TABLE" ||
                this.fieldsData[dataTableFieldIndex].inputType == "DIVISION" ||
                (this.fieldsData[dataTableFieldIndex].input_type == "RICH_TEXT" && this.fieldsData[dataTableFieldIndex].inputType == "MULTI_LINE_TEXT"))
            ) {
              let currentHeight = elements[0].offsetHeight;
              if (elements[0].offsetHeight === 0 || elements[0].offsetHeight < this.fieldsData[dataTableFieldIndex].height) {
                currentHeight = this.fieldsData[dataTableFieldIndex].height;
              } else {
                currentHeight = elements[0].offsetHeight;
              }


              let diff = currentHeight - currentHeight;
              item.listen_height = true;


              let actualDiff =
                this.fieldsData[dataTableFieldIndex].height - currentHeight;
              if (
                this.fieldsData[dataTableFieldIndex].height == currentHeight &&
                (this.fieldsData[dataTableFieldIndex].inputType ==
                  "DATA_TABLE" ||
                  this.fieldsData[dataTableFieldIndex].inputType ==
                    "ENTITY_TABLE" ||
                  this.fieldsData[dataTableFieldIndex].inputType == "DIVISION")
              ) {
                divisionFieldKey = this.fieldsData[dataTableFieldIndex].key
                if(this.checkhideFields(this.fieldsData[dataTableFieldIndex]) || this.fieldsData[dataTableFieldIndex].properties.filed_content == 'Hide'){
                  actualDiff = currentHeight;
                }
                else{
                  actualDiff = this.fieldsData[dataTableFieldIndex].height - currentHeight;
                }
              } else {
                actualDiff =
                  this.fieldsData[dataTableFieldIndex].height - currentHeight;
              }


              cDiff += diff;
              cActualDiff += actualDiff;
            }
            dataTableFieldIndex--;
          }


          if (cDiff > 0) {
            return (
              `position:absolute;left:${item.x}px;top:${
                item.y + cDiff + 40
              }px !important; height:${item.height}px;width:${item.width}px;` +
              (zIndex == -1 ? "z-index : -1;" : "")
            );
          } else {
            if(item?.is_in_division && item.is_in_division == divisionFieldKey){
              return (
                `position:absolute;left:${item.x}px;top:${item.y - 10
                }px;height:${item.height}px;width:${item.width}px;` +
                (zIndex == -1 ? "z-index : -1;" : "")
              );
            }
            else{
              return (
                `position:absolute;left:${item.x}px;top:${
                  item.y - cActualDiff + 20
                }px;height:${item.height}px;width:${item.width}px;` +
                (zIndex == -1 ? "z-index : -1;" : "")
              );
            }
          }
        } else {
          if (item?.table_data_view_type == "Accordion_view") {
            return (
              `position:absolute;overflow-y:auto;scrollbar-width:thin;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;` +
              (zIndex == -1 ? "z-index : -1;" : "")
            );
          }
          else if(item.input_type == "CAROUSEL" && this.getIsMobile){
            return((zIndex == -1 ? "z-index : -1;" : ""));
          }
          else{
            return (
              `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;` +
              (zIndex == -1 ? "z-index : -1;" : "")
            );
          }
        }
      }
    },
  },
};