<template>
  <section
    class="preview-template-view"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <div class="footer-action-block" v-if="!companyProfile && !onlyView">
      <!-- {{ isNotSentformbuilder }} -->
      <el-button
        v-if="getIsFormbuilderMode && isNotSentformbuilder"
        style="margin-right: 165px"
        :class="getIsFormbuilderMode ? 'float-right' : ''"
        icon="el-icon-s-promotion"
        @click="updateFormbuilderTemplate(true)"
      >
        Send Form builder
      </el-button>
      <el-button
        v-if="!this.isExecute && !getIsMobile && checkPerimission('UPDATE') && IframePermissions('EDIT','FormBuilderUserDate') && checkReceiverPermissions('edit')"
        icon="el-icon-edit"
        type="primary"
        plain
        class="float-right"
        @click="goToEditWorkflow()"
        >Edit</el-button
      >
      <el-button
        v-if="!this.isExecute && getIsMobile"
        icon="el-icon-edit"
        type="primary"
        plain
        class="float-right mb-2 p-2"
        @click="goToEditWorkflow()"
      ></el-button>
      <div class="d-flex float-right" style="">
        <el-button
          v-if="
            this.isExecute &&
            !hasNext &&
            this.$route &&
            this.$route.name &&
            this.$route.name == 'companyProfile'
          "
          type="plain"
          :style="
            this.$route &&
            this.$route.name &&
            this.$route.name == 'companyProfile'
              ? 'width:100px;margin-top:-40px'
              : ''
          "
          @click="closeDialog"
          :class="getIsFormbuilderMode ? 'float-right' : ''"
        >
          Cancel
        </el-button>
        <el-button
          v-if="this.isExecute && !hasNext && checkPerimission('UPDATE')"
          type="primary"
          style="margin-top: -42px; margin-right: 4px"
          @click="saveAndnext"
          :class="getIsFormbuilderMode ? 'float-right' : ''"
          :loading="loading"
        >
          {{ isEdit ? "Update" : "Finish" }}
        </el-button>
      </div>
      <el-button
        :class="getIsFormbuilderMode ? 'float-right' : 'mr-2'"
        :style="getIsFormbuilderMode ? '' : 'margin-top: 30px'"
        v-if="this.isExecute && hasNext && checkPerimission('UPDATE')"
        type="primary"
        @click="saveAndnext"
        :loading="loading"
      >
        {{
          isEdit && this.primaryDataPopupData
            ? "Save &amp; Next"
            : "Update &amp; Next"
        }}
      </el-button>
    </div>
    <div
      class="footer-action-block"
      v-if="companyProfile && !onlyView"
      style="z-index: 9999"
    >
      <el-button
        icon="el-icon-edit"
        type="primary"
        size="mini"
        plain
        class="float-right mr-1"
        @click="editTemplateDataTrigger()"
        >Edit</el-button
      >
    </div>
    <div class="template-body-section">
      <el-form @submit.native.prevent v-if="interactiveQuestionsMode">
        <!-- <el-scrollbar
          wrap-style="max-width: calc(100% - 0px);height: calc(100vh - 338px);"
          style="width: 100%; overflow-x: hidden"
        > -->
        <div v-if="isAllQuestionsAnswered" style="text-align: center">
          You have answered all questions.
        </div>
        <preview-interactive-template-form
          v-else
          :fieldsData="fieldsData"
          :isExecute="isExecute"
          :form="form"
          :interactiveQuestionsModeType="interactiveQuestionsModeType"
          :dataId="entityDataId"
          :isFormFormbuilder="fromFormbuilder"
          @onFilledQuestion="updateInteractiveQuestionFillingStatus"
        ></preview-interactive-template-form>
        <!-- </el-scrollbar> -->
      </el-form>

      <el-form v-else @submit.native.prevent>
        <div
          style="max-width: calc(100% - 0px);height: calc(60vh + 0px) !important;padding: 10px;scrollbar-width:thin;overflow-x: scroll;overflow-y: scroll"
        >
          <div
            class="form-builder"
            style="height: 395px; border: 0px solid"
            ref="formBuilder"
            :style="getStylediv"
          >
            <div
              v-if="
                fieldsData &&
                fieldsData.length &&
                !refreshTemplate &&
                !hasRepeatableTemplate
              "
              class="form-fields-holder"
            >
              <div
                v-for="(field, index) of getFieldsData"
                :key="index"
                :style="getExistedStyle(field) || getStyle(field, index)"
              >
                <!-- <div v-if="!refresh && !hideFields.find((e) => e === field.key)"> -->
                <HorizontalLineExecute
                  :data="field"
                  v-if="field.input_type === 'HORIZONTAL_LINE'"
                  :form="form"
                  :is-view="!isExecute"
                ></HorizontalLineExecute>
                <DateExecute
                  :data="field"
                  v-if="field.input_type === 'DATE' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateExecute>
                <DateTimeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE_TIME' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateTimeExecute>
                <calender-execute
                :data="field"
                  v-if="
                    field.input_type === 'CALENDER' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter">
                </calender-execute>
                <icon-execute
                :data="field"
                  v-if="
                    field.input_type === 'ICON' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter">
                </icon-execute>
                <random-text-execute
                  :data="field"
                  v-if="
                    field.input_type === 'RANDOM_TEXT' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter">
                </random-text-execute>
                <DateTimeRangeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE_TIME_RANGE' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateTimeRangeExecute>
                <DateRangeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE_RANGE' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateRangeExecute>
                <CheckBoxGroupExecute
                  :data="field"
                  v-if="
                    field.input_type === 'CHECKBOX_GROUP' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  :fieldsData="getFieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></CheckBoxGroupExecute>
                <RadioGroupExecute
                  :data="field"
                  v-if="
                    field.input_type === 'RADIO_BUTTON_GROUP' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  :fieldsData="fieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></RadioGroupExecute>
                <TimeExecute
                  :data="field"
                  v-if="field.input_type === 'TIME' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></TimeExecute>
                <TimeRangeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'TIME_RANGE' && !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                >
                </TimeRangeExecute>
                <FileExecute
                  :data="field"
                  v-if="field.input_type === 'FILE'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :templatesData="existedTemplateDataValues"
                  :filesInfo="filesInfo"
                ></FileExecute>
                <MultiLineTextExecute
                  :data="field"
                  v-if="
                    (field.input_type === 'MULTI_LINE_TEXT' ||
                      field.input_type === 'RICH_TEXT') &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :isFromView="false"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></MultiLineTextExecute>
                <SingleLineTextExecute
                  :data="field"
                  v-if="
                    (field.input_type === 'SINGLE_LINE_TEXT' ||
                      field.input_type === 'EMAIL' ||
                      field.input_type === 'PASSWORD' ||
                      field.input_type === 'MASKED' ||
                      field.input_type === 'HYPER_LINK') &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></SingleLineTextExecute>
                <MultiSelectExecute
                  :data="field"
                  v-if="
                    field.input_type === 'MULTI_SELECT' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :hideOptions="hideOptions"
                  @applyFormRules="rulesEventEmitter"
                ></MultiSelectExecute>
                <SelectExecute
                  :data="field"
                  v-if="
                    field.input_type === 'SELECT' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :hideOptions="hideOptions"
                  @applyFormRules="rulesEventEmitter"
                ></SelectExecute>
                <YesOrNoExecute
                  :data="field"
                  v-if="
                    field.input_type === 'YES_OR_NO' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></YesOrNoExecute>
                <NumberExecute
                  :data="field"
                  v-if="
                    (field.input_type === 'NUMBER' ||
                      field.input_type === 'ZIP_CODE' ) &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></NumberExecute>

                <PhoneCountryCodeExecute
                  :data="field"
                  v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :primaryData="getNewEntityData"
                ></PhoneCountryCodeExecute>
                <CurrencyExecute
                  :data="field"
                  v-if="
                    field.input_type === 'CURRENCY' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></CurrencyExecute>
                <HeadingExecute
                  :data="field"
                  v-if="field.input_type === 'HEADING'"
                  :form="form"
                  :is-view="!isExecute"
                ></HeadingExecute>
                <CheckBoxExecute
                  :data="field"
                  v-if="
                    field.input_type === 'CHECKBOX' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></CheckBoxExecute>
                <RadioExecute
                  :data="field"
                  v-if="field.input_type === 'RADIO' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></RadioExecute>
                <ESignatureExecute
                  :data="field"
                  v-if="field.input_type === 'SIGNATURE'"
                  :is-view="!isExecute"
                  :form="form"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                ></ESignatureExecute>
                <GlobalVariableExecute
                  :data="field"
                  v-if="field.input_type === 'GLOBAL_VARIABLE'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                ></GlobalVariableExecute>

                <EntityVariableExecute
                  :data="field"
                  v-if="
                    field.input_type === 'ENTITY_VARIABLE' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                  :class="'dataTable' + index"
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></EntityVariableExecute>

                <EntityExecute
                  :data="field"
                  v-if="
                    field.input_type === 'ENTITY' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></EntityExecute>

                <ParagraphExecute
                  :data="field"
                  v-if="field.input_type === 'PARAGRAPH'"
                  :form="form"
                  :is-view="!isExecute"
                ></ParagraphExecute>
                <SingleLineContentExecute
                  :data="field"
                  v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                ></SingleLineContentExecute>
                <AuthorizedSignatureExecute
                  :data="field"
                  v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                  :form="form"
                  :is-view="!isExecute"
                ></AuthorizedSignatureExecute>
                <!-- IMage html formula -->
                <FormulaExecute
                  :data="field"
                  v-if="
                    field.input_type === 'FORMULA' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :fieldsData="fieldsData"
                  :workflowData="getUserFormbuilderData"
                  :entityDataId="entityDataId"
                  :hasLabel="hasLabel"
                  @applyFormRules="rulesEventEmitter"
                ></FormulaExecute>
                <ActionButtonExecute
                  :data="field"
                  v-if="
                    field.input_type === 'ACTION_BUTTON' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :workflowDataId="entityDataId"
                  :fieldsData="fieldsData"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></ActionButtonExecute>
                <ImageExecute
                  :data="field"
                  v-if="field.input_type === 'IMAGE' ||
                  field.input_type === 'GIF'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                ></ImageExecute>
                <VideoExecute
                  :data="field"
                  v-if="field.input_type === 'VIDEO'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                ></VideoExecute>
                <HtmlExecute
                  :data="field"
                  v-if="field.input_type === 'HTML_CONTENT'"
                  :form="form"
                  :is-view="!isExecute"
                ></HtmlExecute>
                <ListExecute
                  :data="field"
                  v-if="field.input_type === 'LIST'  && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></ListExecute>
                <DIVExecute
                  :data="field"
                  v-if="field.input_type === 'DIVISION'"
                  :form="form"
                >
                </DIVExecute>
                <AggregateFunctionExecute
                  :data="field"
                  v-if="
                    field.input_type === 'AGGREGATE_FUNCTION' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                  :workflowDataId="entityDataId"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></AggregateFunctionExecute>
                <QuestionExecute
                  :data="field"
                  v-if="field.input_type === 'QUESTION'"
                  :form="form"
                  :is-view="!isExecute"
                ></QuestionExecute>

                <AutoIncrementExecute
                  :data="field"
                  v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                  :form="form"
                  :is-view="!isExecute"
                  :entityDataId="entityDataId"
                  :entityId="getEntityId"
                  :hasLabel="hasLabel"
                ></AutoIncrementExecute>
                <WeekDaysExecute
                  :data="field"
                  v-if="
                    field.input_type == 'WEEKDAYS' && !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                >
                </WeekDaysExecute>

                <DataTableExecute
                  :class="'dataTable' + index"
                  ref="dataTable"
                  :data="field"
                  v-if="
                    field.input_type === 'DATA_TABLE' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :fieldsData="fieldsData"
                  @updateTableData="updateTableFieldData"
                  @onNewRowAdded="updateTopForBelowElements"
                ></DataTableExecute>
                <EntityTableExecute
                :class="'dataTable' + index"
                  ref="dataTable"
                  :data="field"
                  v-if="
                    field.input_type === 'ENTITY_TABLE' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :fieldsData="fieldsData"
                  @updateTableData="updateTableFieldData"
                  @onNewRowAdded="updateTopForBelowElements"
                  >
                  </EntityTableExecute> 
                <!-- {{ field.input_type }} -->
                <PaymentExecute
                  :data="field"
                  v-if="field.input_type === 'PAY_BUTTON'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></PaymentExecute>
                <StarRatingExecute
                  :data="field"
                  v-if="
                    field.input_type == 'STAR_RATING' && !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                >
                </StarRatingExecute>
                <ConcatenateExecute
                    :data="field"
                    v-if="
                      field.input_type == 'CONCATENATE' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :is-view="!isExecute"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                  >
                </ConcatenateExecute>
                <AudioExecute  :data="field"
                    v-if="
                      field.input_type == 'AUDIO' && !checkhideFields(field)
                    "
                    :form="form"
                    :is-view="!isExecuteCopy"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"></AudioExecute>
                <CaptchaExecute
                  :data="field"
                  v-if="
                    field.input_type == 'CAPTCHA' && !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></CaptchaExecute>

                <QRExecute  
                :data="field"
                  v-if="
                    field.input_type == 'QR' && !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></QRExecute>

                
                <QRReaderExecute  
                :data="field"
                  v-if="
                    field.input_type == 'QR_READER' && !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :is-view="!isExecute"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></QRReaderExecute>
              </div>
              <!-- </div> -->
            </div>
            <RepeatableTemplateExecute
              v-if="hasRepeatableTemplate"
              :templateData="templateData"
              :entityRepeatableTemplateData="entityRepeatableTemplateData"
              :baseWidth="currentFormBuilderWidth"
              :form="form"
              :entitiesData="entitiesData"
              :isSubmit="isSubmit"
              :isView="!isExecute"
              :getDefaultDateFormat="getDefaultDateFormat"
              :singleCompanyTemplateCheckBoxArray="
                singleCompanyTemplateCheckBoxArray
              "
              :entityDataId="entityDataId"
              :max_value="max_value"
              :min_value="min_value"
              :templateRules="templateRules"
              :templateRuleFields="templateRuleFields"
            />
          </div>
        </div>
      </el-form>
    </div>
  </section>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import axios from "@/config/axios";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import dataTableStylesHelper from "@/mixins/dataTableStylesHelper";
import NavigationHelper from "@/mixins/navigationHelper";

import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
var fieldsList = require("./fields.json");
export default {
  name: "templates-PreviewTemplate",
  components: {
    HorizontalLineExecute: () =>
      import("./formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("./formComponentsExecute/DateExecute"),
    DateTimeExecute: () => import("./formComponentsExecute/DateTimeExecute"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () => import("./formComponentsExecute/DateRangeExecute"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    FileExecute: () => import("./formComponentsExecute/FileExecute"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute"),
    
    PaymentExecute: () =>
      import("./formComponentsExecute/PaymentExecute.vue"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () => import("./formComponentsExecute/HeadingExecute"),
    CheckBoxExecute: () => import("./formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () => import("./formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("./formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("./formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("./formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () => import("./formComponentsExecute/ImageExecute"),
    VideoExecute: () => import("./formComponentsExecute/VideoExecute"),
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("./formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("./formComponentsExecute/HtmlExecute"),

    ListExecute: () => import("./formComponentsExecute/ListExecute"),
    DIVExecute: () => import("./formComponentsExecute/DIVExecute.vue"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute"),
    RepeatableTemplateExecute: () => import("./RepeatableTemplateExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    CurrencyExecute: () =>
      import("./formComponentsExecute/CurrencyExecute.vue"),
    PreviewInteractiveTemplateForm: () =>
      import("@/components/templates/PreviewInteractiveTemplateForm"),
    QuestionExecute: () => import("./formComponentsExecute/QuestionExecute"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    AutoIncrementExecute: () =>
      import("./formComponentsExecute/AutoIncrementExecute.vue"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    DataTableExecute: () => import("./formComponentsExecute/DataTableExecute"),
    // //RadioButtonGroupExecute
    RadioGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute"),
    EntityTableExecute: () => 
      import("./formComponentsExecute/EntityTableExecute"),
   AudioExecute: () =>
      import("./formComponentsExecute/AudioExecute"),
   CaptchaExecute: () =>
      import("./formComponentsExecute/CaptchaExecute"),
   QRExecute: () => import("./formComponentsExecute/QRExecute"),
   QRReaderExecute :() => import("./formComponentsExecute/QRReaderExecute.vue"),
   CalenderExecute :() => import("./formComponentsExecute/CalenderExecute.vue"),
   IconExecute :() => import("./formComponentsExecute/IconExecute.vue"),
   RandomTextExecute: () => import("./formComponentsExecute/RandomTextExecute.vue"),
  },

  mixins: [
    entityRelationshipMixin,
    FormbuilderHelper,
    TemplateBuilderHelper,
    dataTableStylesHelper,
    NavigationHelper
  ],
  created() {
    bus.$on("companyProfile", async (data) => {
      this.entityDataID = data.entityDataId;
      await this.setTemplateInfo();
    });
    bus.$on("primaryData", async (data) => {
      let primaryDataTab = data;
      await this.setTemplateInfo();
      console.log(
        "primaryDataprimaryDataprimaryDataprimaryDataprimaryData",
        primaryDataTab
      );
    });
  },
  props: [
    "entityDataId",
    "templateData",
    "hasNext",
    "templateDataId",
    "workflowDetailsId",
    "isExecute",
    "formbuilderStepId",
    "templateDataIds",
    "isEdit",
    "companyProfile",
    "primaryData",
    "companyProfileEdit",
    "entityRelationships",
    "fromFormbuilder",
    "formbuilderDataId",
    "isNotSentformbuilder",
    "editTemplateDataDialog",
    "onlyView",
    "currentStep",
    "currentStepIndex",
    "isFromSenderSide",
    "companyEntityId",
    "formbuilderDetails"
  ],
  data() {
    return {
      form: {
        //   countryCallingCode:{
        // }
      },
      fieldsData: [],
      repeatableTemplateFields: [],
      currentFormBuilderWidth: null,
      name: "",
      description: "",
      type: "",
      slug: "",
      singleCompanyTemplateCheckBoxArray: [],
      storedFormBuilderWidth: null,
      isFlow: false,
      componentName: "",
      formHeight: 600,
      loading: false,
      templateFormData: {},
      primaryDataPopup: "",
      templateCode: null,
      showForm: true,
      existedTemplateDataId: "",
      existedTemplateDataValues: {},
      preparingFormDataLoading: false,
      loadingText: "Loading Template..",

      fieldActions: [],
      templateStyles: {},
      hasRepeatableTemplate: false,
      entityRepeatableTemplateData: {},
      repeatableTemplateFieldsData: {},
      refreshTemplate: false,
      tableStartingPoint: 0,
      loadGV: false,
      primaryDataPopupData: false,
      requiredFields: [],
      currentUserTemplatesData: [],
      pos: {
        x: 0,
        y: 0,
        height: 0,
      },
      hasLabel: true,
      minValue: "",
      fileMinValue: "",
      fileMaxValue: "",
      entitiesData: [],
      isSubmit: false,
      intervalTime: "",
      interactiveQuestionsMode: false,
      interactiveQuestionsModeType: "vertical",
      interactiveQuestionsAreDone: false,
      oldForm: {},
      isAllQuestionsAnswered: false,
      max_value: 1000,
      min_value: 0,
      templateRules: [],
      templateRuleFields: {},
      hideFields: [],
      hideOptions : {},
      disabledFields: [],
      refresh: false,
      files: [],
      randomFlag: "",
      filesInfo: {},
    };
  },

  computed: {
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateUpdateStatus",
      "getSingleCompanyTemplate",
      "getSingleDefaultTemplate",
      "getTemplateErrors",
    ]),

    ...mapGetters("formbuilderData", [
      "getNewWorkflowData",
      "getEntityDataCreateByTemplateStatus",
      "getWorkflowTemplateDataAddStatus",
      "getNewFormbuilderData",
      "getUserFormbuilderData",
      "getFormbuilderDataUpdateStatus",
    ]),
    ...mapGetters("contacts", [
      "getUpdateDocumentPrimaryDetailsData",
      "getUpdateDocumentPrimaryDetailsStatus",
    ]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
      "getPrimaryEntityDataStatus",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
      "getRepeatableTemplateIds",
      "getFormbuilderTemplatesDataUpdateStatus",
      "getFormbuilderTemplatesDataUpdateData",
      "getRequiredTemplatesData",
      "getUploadAndUpdateDocument",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
      "getUserTemplateDataUpdateErrors",
    ]),
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("iFrame",["getIframeUrlInfo"]),
    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (
          inputType === "SINGLE_LINE_TEXT" ||
          inputType === "PASSWORD" ||
          inputType === "EMAIL" ||
          inputType === "MASKED" ||
          inputType === "HYPER_LINK"
        ) {
          styles.push("single-line-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (
          inputType === "NUMBER" ||
          inputType == "PHONE" ||
          inputType == "ZIP_CODE" ||
          inputType == "FAX" ||
          inputType == "CURRENCY"
        ) {
          styles.push("number-field-preview");
        } else if (inputType === "DATE") {
          styles.push("date-field-preview");
        }

        return styles;
      };
    },

    getTemplateSlug() {
      return this.name.toLowerCase().split(" ").join("-");
    },

    getStylediv() {
      return `height:${this.formHeight + 200}px;` + this.getElementStyle;
    },
    getFieldsData() {
      this.fieldsData.forEach((f) => {
        if (
          f.input_type == "ACTION_BUTTON" &&
          f.action_button_type === "TEMPLATE"
        ) {
          this.pos.x = f.x;
          this.pos.y = f.y;
          this.pos.height = f.height;
        }
      });
      this.fieldsData.forEach((f) => {
        if (
          f.input_type != "ACTION_BUTTON" &&
          (this.pos.x > 0 || this.pos.y > 0 || this.pos.height > 0)
        ) {
          if (this.pos.y < f.y) {
            if (this.pos.y + this.pos.height + 150 < f.y) {
              return;
            } else {
              f.y = this.pos.y + this.pos.height + 200;
            }
          }
        }
      });
      return this.fieldsData;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
      }
      return borderStyle;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });
      return obj;
    },

    // getStyle() {
    //   return (item) => {
    //     let itemWidth =
    //       item.styles && item.styles.labelStyle == "right"
    //         ? item.width + 100
    //         : item.width;
    //     let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${itemWidth}px;`;
    //     return style;
    //   };
    // },

    getIsFormbuilderMode() {
      return (
        this.$route.name == "FormbuilderViewStep" ||
        this.$route.name == "FormbuilderEditStep" ||
        this.$route.name == "ApplicationUserFormbuilderViewStep" ||
        this.$route.name == "ApplicationUserFormbuilderEditStep" ||
        this.$route.name == "FormbuilderViewStepSSO_External" ||
        this.$route.name == "FormbuilderEditStepSSO_External"
      );
    },
    currentMenu() {
      if (
        this.getEntityMenu?.menu_list &&
        (this.$route.params.menuId || this.$route.query.menu_id)
      ) {
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        let menu;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    getEntityId() {
      return this.$route.params.entity_id || this.companyEntityId;
    },
  },

  async mounted() {
    await this.getAllFieldList();
    await this.checkRepeatableTemplate();
    await this.setTemplateInfo();
    if (this.$refs.formBuilder && this.$refs.formBuilder.clientWidth) {
      this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    }

    this.$store.commit(
      "companyTemplates/setTemplateErrors",
      {},
      {
        root: true,
      }
    );

    this.$store.dispatch("repeatableTemplates/resetParentEntityData");

    bus.$on("repeatable-template-added", () => {
      this.refreshTemplate = true;
      setTimeout(() => {
        this.refreshTemplate = false;
      }, 1000);
    });
    bus.$on("primaryData", async (data) => {
      let primaryDataTab = data;
      this.primaryDataPopupData = primaryDataTab.addPrimaryDataDialog;
      await this.setTemplateInfo();
    });
  },

  methods: {
    checkReceiverPermissions(access) {
      // If form is approved, whether need to give permissions to view, edit, delete submission for receiver(application user or company user)
      if ((this.$route?.name == 'applicationFormBuilderUserData' || this.$route?.query?.routeType == 'FB'
        || this.$route?.name == 'ApplicationUserFormbuilderViewStep') && this.getUserFormbuilderData?.approval_status == 'APPROVED') 
      {
        if (this.formbuilderDetails?.approval_receiver_permissions) {
          let permissions = this.formbuilderDetails.approval_receiver_permissions;
          if (access == 'view' && permissions.view == true) {
            return true;
          } else if (access == 'edit' && permissions.edit == true) {
            return true;
          } else if (access == 'delete' && permissions.delete == true) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    updateTopForBelowElements(data) {
      this.fieldsData.forEach((e, index) => {
        if (e.listen_height) {
          if (data) {
            this.$set(
              this.fieldsData[index],
              "style",
              this.getStyle(e, index, data.height)
            );
          } else {
            this.$set(this.fieldsData[index], "style", this.getStyle(e, index));
          }
        }
      });
    },
    getExistedStyle(field) {
      return field.style || undefined;
    },

    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    getMinValue(field, data) {
      if (field && data[field.key + "/min_value"]) {
        return data[field.key + "/min_value"];
      }

      return field.min_selection || undefined;
    },

    checkRepeatableIsDisabled(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.disabledFields &&
        this.templateRuleFields.disabledFields[indexKey]
      ) {
        return this.templateRuleFields.disabledFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkRepeatableFields(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.hideFields &&
        this.templateRuleFields.hideFields[indexKey]
      ) {
        return this.templateRuleFields.hideFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkFieldFillStatus(field, data) {
      return (
        this.checkRepeatableIsDisabled(field, data.indexKey) ||
        this.checkRepeatableFields(field, data.indexKey)
      );
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    checkPerimission(access) {
      if (this.getAuthenticatedUser?.is_contact) {
        if (access == "UPDATE") {
          access = "EDIT";
        }
        if (this.getEntityPermission && this.currentMenu) {
          if (
            this.currentMenu?.entity_type == "ENTITY" &&
            this.getEntityPermission.other_entities &&
            this.templateData?._id &&
            this.getEntityPermission.other_entities[this.getEntityDataById._id]
              ?.templates?.[this.templateData._id] &&
            this.getEntityPermission.other_entities[
              this.getEntityDataById._id
            ]?.templates?.[this.templateData._id].indexOf(access) === -1
          ) {
            return false;
          } else if (
            this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
            this.getEntityPermission.relational_entities &&
            this.getEntityPermission.relational_entities[
              this.getEntityDataById._id
            ]?.permissions &&
            this.getEntityPermission.relational_entities[
              this.getEntityDataById._id
            ]?.permissions.indexOf(access) === -1
          ) {
            return false;
          }
        }
        return true;
      } else {
        if (
          this.$route.query?.filter &&
          this.getEntityDataById._id == this.getSingleFilterData.entity_id &&
          this.getSingleFilterData?.permissions?.access_set
        ) {
          if (!this.getSingleFilterData.permissions.access_set.length) {
            return false;
          }
          return (
            this.getSingleFilterData.permissions.access_set.indexOf(access) !=
            -1
          );
        }
      }
      return true;
    },
    updateInteractiveQuestionFillingStatus(status) {
      if (!this.entityDataId || !this.getTemplatesData) {
        if (status == true && this.interactiveQuestionsAreDone) {
          this.$emit("allFieldsCompleted", true);
          this.isAllQuestionsAnswered = true;
        }
        this.interactiveQuestionsAreDone = status;
      }
    },
    async setDataToEntityVariables(data, parent, label, changed) {
      // let entityId = data?._id ? data._id : null;
      if (label) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.getFieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.field_assignable == "read_only" ||
            field.inputType == "ENTITY" ||
            !this.form[field.key] ||
            changed)
        ) {
          if (field.global_variable_entity_field.includes("~")|| field.variable_action === 'RESOURCE_AVERAGE') {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                (data || []).forEach((e) => {
                  if (e?.entityData?.[templateId]?.[key]) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{ parentDataId: e._id, parentDataName: e.parentDataName, },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (field.inputType == "DATA_TABLE") {
                  this.$set(this.form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(this.form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(this.form, field.key, min);
                  } else {
                    this.$set(this.form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (field.inputType == "ENTITY") {
                  valueName = data.entityData[templateId][key + "/name"];
                  this.$set(this.form, field.key + "/default", [value]);
                }
              }
              if (this.form[field.key]) {
                if (data.entityData[templateId][key + "_code"]) {
                  this.form[field.key + "_code"] =
                    data.entityData[templateId][key + "_code"];
                }
                this.form[field.key] = value;
                if (valueName) {
                  this.form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(this.form, field.key, value);
                if (valueName) {
                  this.$set(this.form, field.key + "/name", valueName);
                }
              }
            }
          }
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }
      // if (entityId) {
      //   this.form.parent_data[entityId] = data;
      // } else {
      //   this.form.parent_data = {};
      // }
      this.rulesEventEmitter();
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    async unsetEntityVariables(parent) {
      this.getFieldsData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          if (field?.inputType == "DATA_TABLE") {
            this.$set(this.form, field.key, []);
          } else {
            this.$set(this.form, field.key, "");
          }
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }

      // if (entityId) {
      //   this.form.parent_data[entityId] = [];
      // }
      this.rulesEventEmitter();
    },
    async fetchDependentData(templateIds) {
      if (templateIds.length) {
        await this.$store.dispatch("templatesData/fetchRequiredTemplateData", {
          entity_data_ids: [this.entityDataId],
          template_ids: templateIds.flatMap((e) => e.template_id),
        });
        if (
          this.getRequiredTemplatesData &&
          this.getRequiredTemplatesData.length
        ) {
          templateIds.forEach((e) => {
            let seletedTemplateData = this.getRequiredTemplatesData.find(
              (te) => te.template_id == e.template_id
            );
            if (
              seletedTemplateData?.template_data &&
              seletedTemplateData?.template_data[e.field]
            ) {
              if (e.is_max) {
                this.max_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              } else {
                this.min_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              }
            }
            if (this.max_value < this.min_value) {
              this.max_value = this.min_value;
            }
          });
        }
      }
    },
    dependentFieldsInfo() {
      let templateIds = [];
      if (
        this.entityRepeatableTemplateData.min_linked_field &&
        this.entityRepeatableTemplateData.min_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[2],
          is_max: false,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_linked_field &&
        this.entityRepeatableTemplateData.max_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[2],
          is_max: true,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_records &&
        !this.entityRepeatableTemplateData?.max_linked_field
      ) {
        this.max_value = this.entityRepeatableTemplateData.max_records;
      }
      if (
        this.entityRepeatableTemplateData.min_records &&
        !this.entityRepeatableTemplateData?.min_linked_field
      ) {
        this.min_value = this.entityRepeatableTemplateData.min_records;
      }
      if (this.max_value < this.min_value) {
        this.max_value = this.min_value;
      }
      this.fetchDependentData(templateIds);
    },
    async checkRepeatableTemplate() {
      if (this.fromFormbuilder && this.currentStep?.settings?.is_repeatable) {
        this.entityRepeatableTemplateData = {
          senderAlias: this.currentStep?.settings?.sender_title,
          default_display: "TABLE",
          receiverAlias: this.currentStep?.settings?.receiver_title,
          max_records: this.currentStep?.settings?.max_records,
          templateInfo: this.currentStep?.template,
          max_linked_field: this.currentStep?.settings?.max_linked_field,
          min_linked_field: this.currentStep?.settings?.min_linked_field,
          min_records: this.currentStep?.settings?.min_records,
        };
        let fields = [];
        if (this.currentStep?.template?.sections) {
          await this.currentStep?.template.sections.forEach((sec) => {
            fields = [...fields, ...sec.fields];
          });
        }
        await fields.forEach(async (e) => {
          await this.$set(this.form, e.key, "");
        });
        this.hasRepeatableTemplate = true;
      } else if (this.getEntityDataById && this.getEntityDataById.templates) {
        this.entityRepeatableTemplateData =
          this.getEntityDataById.templates.find(
            (e) => this.templateData && e.template_id == this.templateData._id
          );
        if (
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.is_repeatable
        ) {
          let fields = [];
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo
          ) {
            await this.entityRepeatableTemplateData.templateInfo.sections.forEach(
              (sec) => {
                fields = [...fields, ...sec.fields];
              }
            );
          }
          await fields.forEach(async (e) => {
            await this.$set(this.form, e.key, "");
          });
          this.hasRepeatableTemplate =
            this.entityRepeatableTemplateData.is_repeatable;
          if (this.hasRepeatableTemplate) {
            this.dependentFieldsInfo();
          }
        }
      }
    },
    editTemplateDataTrigger() {
      this.$emit("event", {
        entityDataId: this.entityDataId,
        templateData: this.templateData,
      });
    },
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED" ||
        inputType === "HYPER_LINK"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "SINGLE_LINE_TEXT";
      } else if (
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "FAX" ||
        inputType === "ZIP_CODE" ||
        inputType === "CURRENCY"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME"
      ) {
        inputType = "DATE";
      }
      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },

    updateHeight({ start, end }) {
      this.fieldsData.forEach((f) => {
        if (f.y > start && f.y <= end) {
          f.y = end + (f.y - start);
        }
      });
    },

    getStyleForTable() {
      return (
        "position:absolute;top:" +
        this.tableStartingPoint +
        "px;left:20px;width:97%;"
      );
      // return "top:" + 1000 + "px;left:40px;";
    },

    updateTableFieldData(details) {
      this.$set(this.form, details.key, details.data);
    },

    saveAndnext() {
      if (this.$route && this.$route.query && this.$route.query.dataId) {
        this.entityDataId = this.$route.query.dataId;
      }
      if (!this.isExecute) {
        bus.$emit("next-step", this.form);
        return;
      } else if (this.checkRequiredFields().showError) {
        // this.$notify.error({
        //   title: "Error",
        //   message: `${this.requiredFields.join(",")} ${
        //     this.checkRequiredFields().invalidError
        //       ? " are Invalid"
        //       : " are required"
        //   }`,
        // });
        if (this.checkRequiredFields().customErrorMessage) {
          this.$notify.error({
            title: "Error",
            message: this.checkRequiredFields().customErrorMessage,
          });
        } else if (this.checkRequiredFields().emailFormatError) {
          this.$notify.error({
            title: "Error",
            message: "There is an error in the email format. Please check",
          });
        } else if (this.checkRequiredFields().checkboxMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message:`Please select at least '<span style="color: red;">${this.minValue}</span>' option(s) for '<span style="color: red;font-weight:bold">${this.checkRequiredFields().checkBoxLabel}</span>' Checkbox group to meet the minimum selection requirement.`,
            dangerouslyUseHTMLString: true
        });
          //  this.$message.error(`Please select options that are greater than or equal to ${this.minValue}, to meet the minimum selection requirement.`)
        } else if (this.checkRequiredFields().fileMinMaxCheck) {
          //this.fileMinValue
          this.$notify.error({
            title: "Error",
            message: `Please upload minimum '${this.fileMinValue}' files or maximum '${this.fileMaxValue}' files , to meet the minimum & maximum file(s) requirement.`,
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all required fields",
          });
        }
      } else if (
        this.hasRepeatableTemplate &&
        this.entitiesData.length < this.min_value
      ) {
        let name =
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.templateInfo &&
          this.entityRepeatableTemplateData.templateInfo.name
            ? this.entityRepeatableTemplateData.templateInfo.name
            : "template";
        this.$notify.error({
          title: "Error",
          message:
            "Please add " +
            name +
            " data " +
            (this.entitiesData.length > 0
              ? (this.min_value - this.entitiesData.length || 1) + " more times"
              : ""),
        });
      } else {
        this.checkForDuplicateData();
      }
      this.requiredFields = [];
    },
    async checkForDuplicateData() {
      if (this.hasRepeatableTemplate || this.fromFormbuilder) {
        this.saveTemplateData();
        return;
      }
      this.loading = true;
      this.loadingText = "Checking duplicate data...";
      await this.$store.dispatch("templatesData/checkDuplicateData", {
        data: [
          {
            form: this.form,
            template_id: this.templateData._id,
          },
        ],
        entity_id: this.$route.params.entity_id || this.companyEntityId,
        parent_entityDataId: this.entityDataId || null,
      });
      if (this.getDuplicateDataCheck) {
        if (!this.getDuplicateDataCheck?.allowed) {
          this.$notify.warning({
            title: "Warning",
            message:
              "With this data already " +
              (this.getEntityDataById && this.getEntityDataById.name
                ? this.getEntityDataById.name + " is"
                : "data is") +
              " created.",
          });
          this.loading = false;
          return;
        }
        this.saveTemplateData();
      } else {
        this.loading = false;
        this.$message({
          message: "Error while checking data",
          type: "error",
        });
      }
    },
    checkInBetween(fieldCount, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldCount.between(minValue, maxValue);
    },
    saveTemplateData() {
      if (this.getIsFormbuilderMode) {
        this.updateFormbuilderTemplate();
      } else {
        if (this.isEdit && this.entityDataId) {
          this.updateTemplateData();
        } else {
          this.addNewEntityData();
          if (
            this.getEntityDataById &&
            this.getEntityDataById.entity_type &&
            this.getEntityDataById.entity_type == "GENERAL"
          ) {
            this.updateTemplateData();
          }
        }
      }
    },
    next() {
      if (!this.isExecute) {
        bus.$emit("next-step", this.form);
        return;
      }
    },
    async goToEditWorkflow() {
      if (this.$route.name == "applicationFormBuilderUserData"  || this.$route.name == "application-user-dashboard" || this.$route.name == "ApplicationUserFormbuilderViewStep") {
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true
          }
        );
        this.$router.push({
          name: "ApplicationUserFormbuilderEditStep",
          params: {
            formbuilder_code: this.formbuilderDetails.code,
            formbuilder_data_id: this.formbuilderDataId
          },
          query: {
            origin: "cwdjkrni"
          }
        });
      } else {
        let urlName = "";
        if (this.getIframeUrlInfo != null) {
          urlName = "FormbuilderEditSSO_External";
        } else {
          urlName = "FormbuilderEditStep";
        }
        this.$router.push({
          name: urlName,
          params: {
            formbuilder_code: this.formbuilderDetails.code,
            formbuilder_data_id: this.formbuilderDataId
          }
        });
      }
    },
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      } else {
        return data?.validations?.required;
      }
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (data && data[field.key]) {
          return false;
        }
        return true;
      }
    },
    checkRequiredFields() {
      this.requiredFields = [];
      this.minValue = "";
      this.fileMinValue = "";
      this.fileMaxValue = "";
      let showError = false;
      let invalidError = false;
      let emailFormatError = false;
      let checkboxMinMaxCheck = false;
      let fileMinMaxCheck = false;
      let customErrorMessage = "";
      let checkBoxLabel = "";
      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.hasRepeatableTemplate) {
        this.entitiesData.push(this.form);
      }
      this.entitiesData.forEach((data) => {
        this.fieldsData.forEach((field) => {
          if (field.input_type === "AUTO_INCREMENT_NUMBER") {
            //do nothing
          } else if (
            field.input_type == "WEEKDAYS" &&
            this.checkIsRequired(field) &&
            this.checkIsNotFilled(field, data)
          ) {
            if (field.allow_multiple) {
              customErrorMessage =
                "Please select " +
                field.min_value +
                " options for " +
                field.label +
                " field";
            }
            showError = true;
            this.requiredFields.push(field.label);
          } else if (
            field.validations &&
            field.validations.required &&
            //!data[field.key] &&
            ((!this.checkIsDisabled(field) &&
              !this.checkhideFields(field) &&
              !this.hasRepeatableTemplate) ||
              (this.hasRepeatableTemplate &&
                !this.checkFieldFillStatus(field, data)))
          ) {
            if (
              field.validations.type &&
              field.validations.type == "ARRAY" &&
              field.key &&
              data[field.key] &&
              !data[field.key].length
            ) {
              showError = true;
              this.requiredFields.push(field.label);
            } else if (field.key && !data[field.key]) {
              showError = true;
              this.requiredFields.push(field.label);
            }
          }

          if (
            this.templateData &&
            this.templateData.type == "STANDARD" &&
            this.templateData.slug == "contactdetails"
          ) {
            let NameRe = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
            let PhoneRe =
              /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
            if (field.key == "first_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key].trim().length
              ) {
                customErrorMessage = "Invalid first name";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "last_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key].trim().length
              ) {
                customErrorMessage = "Invalid last name";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "phone_number") {
              if (field && data[field.key] && !PhoneRe.test(data[field.key]).replaceAll(' ', '')) {
                {
                  {
                    data[field.key];
                  }
                }
                customErrorMessage = "Invalid Phone Number";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "email") {
              if (
                data[field.key] &&
                data[field.key].toLowerCase() ==
                  this.getAuthenticatedUser.email.toLowerCase()
              ) {
                this.$message({
                  message: "You cannot add sender email!.",
                  type: "error",
                });
                data[field.key] = "";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                this.requiredFields.push(field.label);
              } else {
                if (
                  data[field.key] &&
                  (!data[field.key].length || !reEmail.test(data[field.key]))
                ) {
                  //   this.$message({
                  //   message: `There is an error in the email format. '${data[field.key]}'  Please check.`,
                  //   type: "error",
                  // });
                  emailFormatError = true;
                  checkboxMinMaxCheck = false;
                  fileMinMaxCheck = false;
                  showError = true;
                  invalidError = true;
                  this.requiredFields.push(field.label);
                }
              }
            }
          }
          if (
            field.inputType == "FILE" &&
            field.validations.multiple &&
            data[field.key] &&
            data[field.key].files &&
            data[field.key].files.length &&
            !this.checkInBetween(
              data[field.key].files.length,
              field.validations.min_document_files,
              field.validations.max_document_files
            )
          ) {
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = true;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.fileMinValue = field.validations.min_document_files;
            this.fileMaxValue = field.validations.max_document_files;
          }
          if (
            field.inputType == "CHECKBOX_GROUP" &&
            data[field.key] &&
            data[field.key].length &&
            data[field.key].length < this.getMinValue(field, data)
          ) {
            customErrorMessage = field.default_error_message
            checkBoxLabel = field.label
            checkboxMinMaxCheck = true;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType == "CHECKBOX_GROUP" &&
            (!data[field.key] || !data[field.key].length)
          ) {
            customErrorMessage = field.default_error_message
            checkBoxLabel = field.label
            checkboxMinMaxCheck = true;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
        });
      });
      return {
        showError,
        invalidError,
        emailFormatError,
        checkboxMinMaxCheck,
        customErrorMessage,
        checkBoxLabel,
        fileMinMaxCheck,
      };
    },
    async updatedFormbuilderStepData(sendForm) {
      let stepsData = (
        this.getUserFormbuilderData.formbuilder_steps_data || []
      ).map((e, index) => {
        if (index == this.currentStepIndex) {
          e.created_contacts = this.entitiesData.flatMap((e) => e._id);
        }
        return e;
      });
      await this.$store.dispatch("formbuilderData/updateFormbuilderData", {
        id:
          this.$route.params && this.$route.params.formbuilder_data_id
            ? this.$route.params.formbuilder_data_id
            : this.formbuilderDataId,
        formbuilder_steps_data: stepsData,
      });
      if (this.getFormbuilderDataUpdateStatus) {
        this.$notify.success({
          title: "Success",
          message: "Form builder updated successfully",
        });
        if (!sendForm) {
          bus.$emit("next-step", {});
          if (!this.hasNext) {
            bus.$emit("next-step", {
              finish: true,
            });
            return;
          }
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error form builder update",
        });
      }
    },
    async updateFormbuilderTemplate(sendForm) {
      this.loading = true;
      let form = {};
      if (this.hasRepeatableTemplate) {
        if (this.currentStep?.standard_template) {
          /* Form builder Mixin method (Update contacts)*/
          await this.updatedContactDetails(
            this.entitiesData,
            this.entitiesData,
            true,
            this.currentStep?.settings?.contact_entity,
            this.currentStep?.settings?.contact_entity_type,
            this.getUserFormbuilderData?.contact
          );
          if (this.getUpdateDocumentPrimaryDetailsStatus) {
            this.entitiesData = this.entitiesData.map((user) => {
              let createdUser = (this.getUpdateDocumentPrimaryDetailsData || [])
                .filter((e) => e.email && e._id)
                .find((e) => e.email.toLowerCase() == user.email.toLowerCase());
              if (createdUser?._id) {
                user._id = createdUser._id;
                user.indexKey = createdUser._id;
              }
              return user;
            });
            /* Update form builder step data*/
            await this.updatedFormbuilderStepData(sendForm);
          }
          this.loading = false;
          return;
        }
        let key = "";
        if (
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.templateInfo &&
          this.entityRepeatableTemplateData.templateInfo.name
        ) {
          let name = this.entityRepeatableTemplateData.templateInfo.name;
          key = name.toLowerCase().split(" ").join("_");
        } else {
          key = "templateData";
        }
        let promisesAll = await this.entitiesData.map(async (e) => {
          //Update parent entity data by entity variable - mixin method
          await this.updateParentDataByEntityVariable(this.fieldsData, e);
          for (let [key, value] of Object.entries(e)) {
            if (
              value &&
              typeof value == "object" &&
              value.type == "DOCUMENT" &&
              value.new == true &&
              value.files
            ) {
              this.loadingText = "Uploading files...";
              let path = await this.uploadFile(value.files);
              this.$set(e, key, path);
              this.$set(e, key, false);
              // this.form[key].path = path;
              // this.form[key].new = false;
            }
          }
          if (e.isNewData) {
            delete e.isNewData;
          }

          return e;
        });
        form[key] = await Promise.all(promisesAll);
      } else {
        //Update parent entity data by entity variable - mixin method
        await this.updateParentDataByEntityVariable(this.fieldsData, this.form);
        form = await this.prepareFormData();
      }
      let params = {
        template_data: form,
        template_id: this.templateData._id,
      };
      if (this.templateDataId) {
        params.template_data_id = this.templateDataId;
      }
      if (this.$route.params && this.$route.params.formbuilder_data_id) {
        params.formbuilder_data_id =
          this.$route.params && this.$route.params.formbuilder_data_id
            ? this.$route.params.formbuilder_data_id
            : this.formbuilderDataId;
      }
      await this.$store.dispatch(
        "templatesData/updateUserFormbuilderTemplateData",
        params
      );
      if (this.getFormbuilderTemplatesDataUpdateStatus) {
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Data updated successfully",
        });
        if (
          this.getFormbuilderTemplatesDataUpdateData &&
          this.getFormbuilderTemplatesDataUpdateData._id
        ) {
          this.updateTempleteCompletionStatus(
            this.getFormbuilderTemplatesDataUpdateData._id,
            sendForm
          );
        }
        if (!sendForm) {
          bus.$emit("next-step", {});
          if (!this.hasNext) {
            bus.$emit("next-step", {
              finish: true,
            });
            return;
          }
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },
    async updateTemplateData() {
      try {
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          const promises = await this.entitiesData.map(async (e) => {
            //Update parent entity data by entity variable - mixin method
            await this.updateParentDataByEntityVariable(this.fieldsData, e);
            for (let [key, value] of Object.entries(e)) {
              if (
                value &&
                typeof value == "object" &&
                value.type == "DOCUMENT" &&
                value.new == true &&
                value.file
              ) {
                this.loadingText = "Uploading files...";
                let path = await this.updatedAndUploadDocument(value.file);
                this.$set(e[key], "path", path);
                this.$set(e[key], "new", false);
                // this.form[key].path = path;
                // this.form[key].new = false;
              }
            }
            if (e.isNewData) {
              delete e.isNewData;
            }

            return e;
          });
          form[key] = await Promise.all(promises);
        } else {
          let incrementFields = this.fieldsData.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }
          // form = this.form;
          //Update parent entity data by entity variable - mixin method
          await this.updateParentDataByEntityVariable(
            this.fieldsData,
            this.form
          );
          form = await this.prepareFormData();
          if (
            this.form &&
            this.form.first_name &&
            this.form.last_name &&
            this.form.first_name.length &&
            this.form.last_name.length &&
            !this.form.name
          ) {
            this.form.name = this.form.first_name + " " + this.form.last_name;
          }
        }
        let params = {
          template_data: form,
          entity_data_id: this.entityDataId,
          template_id: this.templateData._id,
          entity_id: this.$route.params.entity_id || this.companyEntityId,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
        this.loading = false;
        if (this.getTemplatesDataUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });

          // this.updateTempleteCompletionStatus();
          if (!this.getIsFormbuilderMode) {
            // check relationships
            await this.updateEntitiesData();
          }
          if (this.companyProfileEdit) {
            // bus.$emit("companyProfileEdit", {});
            this.refreshProfile();
          }
          if (!this.hasNext) {
            bus.$emit("next-step", {
              finish: true,
            });
            return;
          } else {
            bus.$emit("next-step", {});
          }
        } else {
          let msg = "Error while updating";
          if (this.getUserTemplateDataUpdateErrors?.message) {
            msg = this.getUserTemplateDataUpdateErrors.message;
          }
          this.$notify.error({
            title: "Error",
            message: msg,
          });
        }
      } catch (err) {
        console.log("updateTemplateData",err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },
    refreshProfile() {
      this.$emit("companyProfileEditEvent", {
        activeName: this.templateData.name,
      });
    },
    closeDialog() {
      this.$emit("close", {
        editTemplateDataDialog: false,
      });
    },
    async updateTempleteCompletionStatus(template_data_id, sentFlow) {
      let params = {
        entity_data_id: this.entityDataId,
        step_type: "TEMPLATE",
        template_data_id: this.templateDataId || template_data_id,
        template_id: this.templateData._id,
        formbuilder_step_id: this.formbuilderStepId,
        formbuilder_data_id: this.formbuilderDataId,
        completion_status:
          this.getRquiredFieldsCount() === this.getTotalFieldsCount(),
      };
      await this.$store.dispatch(
        "formbuilderData/updateStepCompletionStatus",
        params
      );
      if (sentFlow) {
        bus.$emit("send-formbuilder", {});
      }
    },

    async addTemplateDateToEntity() {
      try {
        let data = {
          entity_data_id: this.entityDataId,
          template_id: this.templateData._id,
          template_data: await this.prepareFormData(),
          entity_step_id: this.formbuilderStepId,
          entity_id: this.$route.params.entity_id,
          template_completion_status:
            this.getRquiredFieldsCount() === this.getTotalFieldsCount(),
        };

        if (this.existedTemplateDataId) {
          data.template_data_id = this.existedTemplateDataId;
        }
        await this.$store.dispatch(
          "formbuilderData/addTemplateDataToWorkFlowData",
          data
        );
        if (this.getWorkflowTemplateDataAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });

          bus.$emit("next-step", {
            message: "Template saved",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at saving data",
          });
        }
      } catch (err) {
        console.log("addTemplateDateToEntity",err);
      }
    },
    async addNewEntityData() {
      try {
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          form[key] = this.entitiesData.map((e) => {
            if (e.isNewData) {
              delete e.isNewData;
            }
            return e;
          });
        } else {
          // form = this.form;
          let incrementFields = this.fieldsData.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }

          form = await this.prepareFormData();
          if (
            form &&
            form.first_name &&
            form.last_name &&
            form.first_name.length &&
            form.last_name.length &&
            !form.name
          ) {
            form.name = form.first_name + " " + form.last_name;
          }
        }
        let data = {
          entity_details_id: this.workflowDetailsId,
          template_id: this.templateData._id,
          template_data: form,
          user_id: this.getNewFormbuilderData,
          entity_step_id: this.formbuilderStepId,
          entity_id: this.$route.params.entity_id || this.companyEntityId,
          template_completion_status:
            this.getRquiredFieldsCount() === this.getTotalFieldsCount(),
          entity_data_id: this.entityDataId ? this.entityDataId : null,
        };
        if (this.existedTemplateDataId) {
          data.template_data_id = this.existedTemplateDataId;
        }
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          data
        );

        if (this.getEntityDataCreateByTemplateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });

          // we have to do entity stuff
          await this.updateEntitiesData();
          //   this.$store.commit("formbuilderData/setNewWorkflowUser", null, {
          //     root: true,
          //   });
          if (this.getNewEntityData) {
            bus.$emit("next-step", {
              newEntityData: this.getNewEntityData,
            });
          }
          if (!this.hasNext) {
            bus.$emit("next-step", {
              finish: true,
            });
            return;
          }
        } else {
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
        }
      } catch (err) {
        console.log("addNewEntityData",err);
      }
    },

    getRquiredFieldsCount() {
      return this.fieldsData.filter(
        (e) => e.validations && e.validations.required && this.form[e.key]
      ).length;
    },

    getTotalFieldsCount() {
      return this.fieldsData.filter(
        (e) => e.validations && e.validations.required && !e.value
      ).length;
    },

    async setTemplateInfo() {
      this.loading = true;
      if (this.currentStep?.standard_template) {
        await this.setTemplateElements(this.templateData);
        this.loading = false;
        return;
      }
      if (this.templateDataId) {
        await this.fetchUserTemplateData();
      } else if (
        !this.templateDataId &&
        (this.getNewFormbuilderData || this.formbuilderStepId)
      ) {
        await this.fetchUserExistedTemplateData();
      }
      if (this.entityDataId) {
        await this.fetchUserExistedTemplateData();
      }
      await this.setTemplateElements(this.templateData);

      this.oldForm = { ...this.form };
      this.loading = false;
    },

    calculateRelativeLeft(x) {
      return x;
    },

    calculateRelativeTop(y) {
      return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "RADIO":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
      //   );
      // }
      return width;
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          //...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        // if (width > field.max_width) {
        //   field.width = field.max_width;
        // } else if (width < field.min_width) {
        //   field.width = field.min_width;
        // } else {
        field.width = width;
        // }
        // if (height > field.max_height) {
        //   field.height = field.max_height;
        // } else if (height < field.min_height) {
        //   field.height = field.min_height;
        // } else {
        field.height = height;
        // }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        if (this.$route.params.entity_id || this.companyEntityId) {
          field.parent_entityId =
            this.$route.params.entity_id || this.companyEntityId;
          field.parent_entityDataId = this.entityDataId;
        }
        if (field.input_type === "DATA_TABLE") {
          field.data_table_columns = (field.data_table_columns || []).map(
            (e) => {
              e.parent_entityId =
                this.$route.params.entity_id || this.companyEntityId;
              e.parent_entityDataId = this.entityDataId;
              if (this.templateData?._id) {
                e.template_id = this.templateData._id;
              }
              return e;
            }
          );
        }
        return field;
      });
    },
    async setTemplateElements(templateData) {
      this.name = templateData && templateData.name;
      this.description = templateData && templateData.description;
      this.type = templateData && templateData.type;
      this.slug = templateData && templateData.slug;
      this.templateStyles = templateData && templateData.styles;
      this.interactiveQuestionsMode =
        templateData && templateData.use_interactive_questionare;
      this.interactiveQuestionsModeType =
        templateData && templateData.interactive_questionare_type;
      this.storedFormBuilderWidth =
        templateData && templateData.page_base_width;
      let defaultSection =
        templateData && templateData.sections.find((x) => x.type == "DEFAULT");
      // Todo need to change for multiple sections
      // this.fieldsData = defaultSection.fields;

      // we are sorting all fields depends on x,y cordinates to flow in the form
      defaultSection.fields = defaultSection.fields.sort((a, b) => {
        if (a.y - b.y < 10) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });

      this.fieldsData = this.setSizeAndPositionForElements(
        defaultSection.fields
      );
      this.fieldsData = this.fieldsData.sort((a, b) => a.order - b.order);
      if (
        templateData &&
        templateData.sections &&
        templateData.sections[0] &&
        templateData.sections[0].checkBoxGroup
      ) {
        this.singleCompanyTemplateCheckBoxArray =
          templateData.sections[0].checkBoxGroup;
      }
      this.templateRules = templateData.rules ? [...templateData.rules] : [];
     this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      this.repeatableTemplateFields = this.fieldsData.filter(
        (e) =>
          e.input_type == "ACTION_BUTTON" && e.action_button_repeating_template
      );
      if (this.currentStep?.standard_template) {
        this.entitiesData = (this.currentStep.templateDataId || []).map(
          (el) => {
            el.indexKey = el._id;
            return el;
          }
        );
      } else if (this.templateDataId && this.getTemplatesData) {
        this.showForm = false;
        if (
          this.hasRepeatableTemplate &&
          this.getTemplatesData?.template_data
        ) {
          this.entitiesData =
            this.getTemplatesData.template_data[
              Object.keys(this.getTemplatesData.template_data)[0]
            ];
          await this.$store.dispatch(
            "templatesData/fetchRepeatableTemplateIds",
            this.entitiesData.length
          );
        } else {
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.getTemplatesData.template_data
          );
        }
      } else if (this.existedTemplateDataId && this.existedTemplateDataValues) {
        if (this.hasRepeatableTemplate) {
          if (
            this.existedTemplateDataValues &&
            Object.keys(this.existedTemplateDataValues) &&
            Object.keys(this.existedTemplateDataValues).length > 1
          ) {
            if (
              this.existedTemplateDataValues &&
              this.existedTemplateDataValues.indexKey
            ) {
              this.entitiesData.push(this.existedTemplateDataValues);
            } else {
              await this.$store.dispatch(
                "templatesData/fetchRepeatableTemplateIds",
                this.entityRepeatableTemplateData.max_records || 10
              );
              this.entitiesData.push({
                ...this.existedTemplateDataValues,
                ...{
                  indexKey:
                    this.getRepeatableTemplateIds &&
                    this.getRepeatableTemplateIds[0]
                      ? this.getRepeatableTemplateIds[0]
                      : (Math.random() + 1).toString(36).substring(7),
                },
              });
            }
          } else {
            this.entitiesData =
              this.existedTemplateDataValues[
                Object.keys(this.existedTemplateDataValues)[0]
              ];
          }
          if (this.entitiesData && this.entitiesData.length) {
            this.entitiesData.forEach((dat, index) => {
              this.applyRulesOnRepeatableData(
                [],
                this.templateRules,
                index,
                dat.indexKey,
                true
              );
            });
          }
        } else {
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.existedTemplateDataValues
          );

          // if (this.interactiveQuestionsMode) {
          //   this.interactiveQuestionsAreDone = false;
          // }
        }
      }

      defaultSection.fields = defaultSection.fields.sort((a, b) => {
        return a.y - b.y;
      });

      let lastElement = defaultSection.fields[this.fieldsData.length - 1];

      this.tableStartingPoint = lastElement.y + lastElement.height + 30;

      this.formHeight = lastElement.y + lastElement.height + 200;
      // }
    },

    setTemplateDataToFormFields(fieldsData, data) {
      fieldsData.forEach((field) => {
        if (field.inputType === "AUTO_INCREMENT_NUMBER") {
          this.$set(
            this.form,
            field.key + "_prefix",
            data && data[field.key + "_prefix"]
              ? data[field.key + "_prefix"]
              : {}
          );
        }

        if (field.inputType === "PHONE_COUNTRY_CODE") {
          this.$set(
            this.form,
            field.key + "_code",
            data && data[field.key + "_code"] ? data[field.key + "_code"] : {}
          );
        }
        if (field.inputType === "CURRENCY") {
          this.$set(
            this.form,
            field.key + "_currency",
            field.validations.currency
          );
        }
        if (
          field.inputType === "SINGLE_LINE_TEXT" &&
          field.input_type === "MASKED"
        ) {
          this.$set(
            this.form,
            field.key + "_masked",
            data && data[field.key + "_masked"]
              ? data[field.key + "_masked"]
              : ""
          );
        }
        if(field.inputType ==="PAY_BUTTON")
        {
          this.$set(
            this.form,
            field.key + "_info",
            data && data[field.key + "_info"]?data[field.key + "_info"]:{}
          );
          this.$set(
            this.form,
            field.key + "_history",
            data && data[field.key + "_history"]?data[field.key + "_history"]:[]
          );
        }
        if (field.input_type === "VIDEO") {
          this.$set(
            this.form,
            field.key + "_video",
            data && data[field.key + "_video"]
            // data && data[field.key + "_video"] ? data[field.key + "_video"] : {}
          );
        }
        if (field.input_type == "QUESTION" && field.has_other_option) {
          this.$set(
            this.form,
            `${field.key}/otheroption`,
            data[field.key + "/otheroption"]
              ? data[field.key + "/otheroption"]
              : ""
          );
        }
        if (field.input_type == "CHECKBOX_GROUP") {
          this.$set(
            this.form,
            field.key,
            data[field.key] ? data[field.key] : ""
          );
        }
        if (field.input_type != "CHECKBOX_GROUP" && data && data[field.key]) {
          this.$set(this.form, field.key, data[field.key]);
        }
        if (data && data[field.key + "/name"]) {
          this.$set(this.form, field.key + "/name", data[field.key + "/name"]);
        }
      });
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },

    async saveForm() {
      bus.$emit("finish", this.form);
    },
    async fetchUserTemplateData() {
      await this.$store.dispatch("templatesData/fetchUserTemplateData", {
        template_data_id: this.templateDataId,
      });
    },
    async fetchUserExistedTemplateData() {
      if (this.entityDataId) {
        await this.$store.dispatch("templatesData/fetchUserTemplateData", {
          entity_data_id: this.entityDataId,
          template_id: this.templateDataId
            ? this.templateDataId
            : this.templateData && this.templateData._id,
        });
        if (this.getTemplatesData && this.getTemplatesData.length) {
          this.currentUserTemplatesData = this.getTemplatesData.template_data;
          this.existedTemplateDataId = this.getTemplatesData[0]._id;
          this.existedTemplateDataValues =
            this.getTemplatesData[0].template_data;
        }
      } else {
        this.existedTemplateDataId = null;
        this.existedTemplateDataValues = {};
      }
    },

    async updateEntitiesData() {
      // ** mixin method ** //
      let toAssignRelationshipsData = this.prepareRelationshipData(
        this.fieldsData,
        this.getCurrentEntityRelationships,
        this.entityDataId
      );

      if (toAssignRelationshipsData.length) {
        await this.$store.dispatch(
          "entityRelationships/assignFieldsEntityRelationshipData",
          toAssignRelationshipsData
        );
      }
    },

    async prepareFormData() {
      this.preparingFormDataLoading = true;

      this.loading = "Preparing template data...";
      for (let [key, value] of Object.entries(this.filesInfo)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loading = true;
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.filesInfo[key].path = path;
          this.filesInfo[key].new = false;
          this.form[key] = this.filesInfo[key];
        }
      }
      this.loading = false;

      this.preparingFormDataLoading = false;
      return this.form;
    },

    async autoIncrement() {
      try {
        let params = {
          entity_data_id: this.entityDataId,
          template_id: this.templateData._id,
        };
        this.loading = true;
        // await this.$store.dispatch("templatesData/fetchinvoiceinfo", params);

        // if (this.getformtemplateinvoiceinfo) {
        //   this.loading = false;
        //   return this.getformtemplateinvoiceinfo;
        // } else {
        //   return {};
        // }
        const response = await axios.get("get-form-template-invoice-info", {
          params,
        });
        return response.data.data;
      } catch (error) {
        console.log("autoIncrement",error);
        return {};
      }
    },
    // methods to file upload formbuilder
    async uploadFile(file) {
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "template-data-documents");
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(filesData);
    },
    async updatedAndUploadDocument(file) {
      let params = {
        data1: {
          file_name: file.raw.name,
          file_type: file.raw.type,
          file_size_in_kb: file.raw.size / 1000,
        },
        data2: file.raw,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentAndGetUrl",
        params
      );
      if (this.getUploadAndUpdateDocument) {
        return this.getUploadAndUpdateDocument;
      } else {
        return "";
      }
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );
      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        }
        //  else {
        //   this.$message("Sorry ! Error while file uploading");
        // }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    async getAllFieldList() {
      try {
        // let params = "form=true&content=true"
        // await this.$store.dispatch("standardFields/getFieldLists", params);
        if (fieldsList) {
          //   let dFieldList = this.fieldsList;
          this.fieldActions = [
            ...fieldsList.allFormFields,
            ...fieldsList.allContentFields,
            ...fieldsList.allAdvancedFields,
          ];
        }
      } catch (error) {
        console.log("getAllFieldList",error);
      }
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },
    // "$route.params.workflow_data_id"(id) {
    //   this.workflowDataId = id;
    // },
    templateData: async function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      await this.setTemplateInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-template-view {
  .header-action-block {
    margin-right: 10px;
  }
  .template-body-section {
    .el-scrollbar {
      border: 1px solid #e4e7ec;
      max-height: 65vh;
      max-width: 80vw;
    }
    margin-top: 10px;
    width: 99%;
    @media (max-width: 766.98px) {
      width: 100% !important;
    }
  }
}
.template-body-section {
  .form-builder {
    scrollbar-width: thin;
    overflow-x: auto;
    overflow-y: auto;
    .form-fields-holder {
      @media (max-width: 991.98px) {
        & > div {
          position: relative !important;
          left: 10px !important;
          top: 0px !important;
          margin-top: 30px;
          width: calc(100% - 20px) !important;
          input {
            width: 100%;
          }
          .heading {
            margin-top: 40px !important;
          }
          p {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.sub-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em !important;
  border: 1px solid #285ed3;
  background: transparent;
  color: #285ed3 !important;
  margin-right: 10px !important;
  &:hover {
    color: white !important;
    background: #285ed3;
    border: 1px solid #285ed3 !important;
  }
}
.edit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c !important;
  &:hover {
    color: white !important;
    background: #f68c8c !important;
    border: 1px solid #f68c8c !important;
  }
}
.preview-template-view {
  margin: 1em 0em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    margin-left: 10px;
    z-index: 100;
    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    margin-right: 10px !important;
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }
  .footer-action-block {
    //position: relative !important;
    margin-bottom: 3em;
    .el-button {
      margin-top: -39px;
      display: block;
      padding: 0.7em 1em;
      margin-right: 20px;
      min-width: 20px;
      font-size: 1em;
    }
    .required-fields-info {
      top: 10px;
      font-size: 14px;
      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }
  .sty {
    margin-top: 500px;
  }
  .form-builder {
    position: relative;
    @media (max-width: 991.98px) {
      width: 100%;
    }
    width: 1200px;
    min-height: calc(100vh - 150px) !important;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
}
</style>
